<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import Multiselect from "vue-multiselect";

import {
	audienceMethods,
  projectMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Audiences",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    Multiselect
  },
  data() {
    return {
      title: "",
      items: [],
			currentPage:1,
			perPage:9,
      rows:0,
			isLoading:false,
      audiences:[],
      confirmDelete:false,
      audience: null,
      query:'',
      empty_list_config:{
            "title" : this.$t("audiences.empty_search_title"),
            "linkButtonText" : this.$t("common.reset_search"),
            icon: "fa-cubes"
        },
        empty_config:{
            "title" : this.$t("audiences.empty_title"),
            "subtitle" : this.$t("audiences.empty_text"),
            "buttonText" : this.$t("audiences.new_audience"),
            icon: "fa-cubes",
            academy: "https://academy.gopersonal.ai/guia-de-usuario/segmentacion/audiencias",
        },
        showCopyToProjectModal : false,
        projects:[],
        auxProject: null
    };
  },
  created(){
		this.debounceAudiences = _debounce(this.searchAudiences.bind(this), 1000);
	},
	mounted(){
		this.loadAudiences();
	},
  methods:{
		...audienceMethods,
    ...projectMethods,
    loadProjects(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
			const params = {
				q : `limit=50`
			}

			this.getProjects(params).then((projects)=>{
        if(projects&& projects.data){
          this.projects= projects.data;
          this.projects = this.projects.filter(p=> p._id != localStorage.getItem('current_project'));
        }
      }).catch(()=>{
        
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    onRemoveAudience(audience){
      this.audience = audience;
      this.confirmDelete = true;
    },
    onConfirmRemoveAudienceClicked(){
      let loader = this.$loading.show();

      this.deleteAudience(this.audience._id).then(()=>{
        this.currentPage=1;
        this.loadAudiences();
        this.$notify({ type: 'success', text: this.$t("audiences.delete_audience_success"),title:  this.$t("audiences.title") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("audiences.delete_audience_error"),title:  this.$t("audiences.title") });
      }).finally(()=>{
        loader.hide();
      })
    },
		loadAudiences(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
			const params = {
				q : `where[project]=${localStorage.getItem('current_project')}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
			}
			this.getAudiences(params).then((audiences)=>{
        if(audiences&& audiences.data){
          this.audiences= audiences.data;
          this.rows= audiences.totalCount
				}
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("audiences.get_audiences_error"),title:  this.$t("audiences.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    searchAudiences(query){
      
      let loader = this.$loading.show();
      this.isLoading = true;
      
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("audiences.empty_search_text").replaceAll('#', this.query);
      
      if(query){
        this.audiences=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getAudiences(params).then((audiences)=>{
          if(audiences&& audiences.data){
            this.audiences= audiences.data;
            this.rows= audiences.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadAudiences();
      }
    },
		onAudienceListPageClicked(){
			this.loadAudiences();
		},	
    onEditAudienceClicked(data){
      this.$router.push({
            path: `/audience?id=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onCreateAudienceClicked(){
      this.$router.push({
            path: "/audience",
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onResetSearchClicked(){
      this.query = '';
      this.loadAudiences();
    },
    onCopyAudience(data){
      this.audience = data;
      this.loadProjects();
      this.showCopyToProjectModal = true;
    },

    onConfirmCopyAudience(){
      if(this.auxProject){
        delete this.audience._id;
        delete this.audience.createdAt;
        delete this.audience.createdBy;
        delete this.audience.updatedAt;
        delete this.audience.updatedBy;

        this.audience.project = this.auxProject._id;
        
        const loader = this.$loading.show()
        this.createAudience(this.audience).then(()=>{
          this.$notify({ type: 'success', text: this.$t("audiences.copy_to_project_audience_success"),title:  this.$t("audiences.title") });
          this.auxProject = null;
          this.audience = null;
          this.showCopyToProjectModal = false;
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("audiences.copy_to_project_audience_error"),title:  this.$t("audiences.title") });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        this.showCopyToProjectModal = false;
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('audiences.title')" :items="items" />
    <div class="row">
      <div class="col">
        <div class="card">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceAudiences($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="text-sm-end">
                <button
                    type="button"
                    class="btn btn-primary mb-0 me-0"
                    @click="onCreateAudienceClicked"
                    v-if="audiences.length>0 || query!=''">
                    <i class="mdi mdi-plus me-1"></i> {{$t('audiences.new_audience')}}
                </button>
                </div>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && audiences.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && audiences.length == 0 && query ==''" @onButtonClicked="onCreateAudienceClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && audiences.length > 0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{$t('audiences.new_audience_name')}}</th>
                      <th scope="col">{{$t('audiences.users')}}</th>
                      <th scope="col">{{$t('audiences.total')}}</th>
                      <th scope="col">{{$t('audiences.revenue_user')}}</th>
                      <th scope="col">{{$t('audiences.conversion_rate')}}</th>
                      <th scope="col">{{$t('audiences.avgOrder')}}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in audiences" :key="data.id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditAudienceClicked(data)">
                          <a href="javascript: void(0);" class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                        <p class="text-muted mb-0 small interact" v-on:click="onEditAudienceClicked(data)">{{ data.description }}</p>
                      </td>
                      <td>{{ data.metrics?.count || '-' }}</td>
                      <td>{{ data.metrics?.total ?  `$${data.metrics?.total.toLocaleString('es')}` : '-' }}</td>
                      <td>{{ data.metrics?.total && data.metrics?.count && data.metrics?.count !=0 ?  `$${(data.metrics.total / data.metrics.count)?.toLocaleString('en')}` : '-' }}</td>
                      <td>{{ data.metrics?.convertionRate ?  `%${data.metrics?.convertionRate.toLocaleString('es')}` : '-' }}</td>
                      <td>{{ data.metrics?.avgOrder ?  `$${data.metrics?.avgOrder.toLocaleString('es')}` : '-' }}</td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 fas fa-share-square interact" v-on:click="onCopyAudience(data)"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditAudienceClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveAudience(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center empty-list col-xl-12 mt-4" v-if="!isLoading && audiences.length == 0">
                        <span> {{ $t("audiences.empty_list") }} </span>
                  <div> <i class="bx bx-group me-1 text-muted display-4"/> </div>
              </div>
              <div class="row mb-4" v-if="rows > perPage">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                                @input="onAudienceListPageClicked"
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                            ></b-pagination>
                        </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveAudienceClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{$t('audiences.remove_audience')}}</p>
        </b-modal>
        <b-modal
          :title="$t('personalizations.copy_to_project_title')"
          v-model="showCopyToProjectModal"
          @ok.prevent="onConfirmCopyAudience"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <div class="row">
            <div class="col">
                <label>{{$t('personalizations.project')}}*</label>
                <multiselect
                  :options="projects" 
                  v-model="auxProject"
                  track-by="_id" 
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  :placeholder= "$t('projects.select_placeholder')"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="form control"
                />
            </div>
          </div>
          <div class="row">
            <div class="col mt-4 bg-info bg-gradient text-white p-3 ms-3 me-3">
              <i class="mdi mdi-alert-circle-outline"></i> <p class="small">{{ $t('audiences.copy_to_project_info') }}</p>
            </div>
          </div>
        </b-modal>
    </div>
    <!-- end row -->
  </Layout>
</template>
<style scoped>
.interact{
  cursor: pointer;
}
</style>
